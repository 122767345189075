p {
  margin-top: 8px;
  margin-bottom: 8px;
}

.page-title {
  font-size: 1.8rem;
  border-bottom: 1px solid var(--color-gray-4);
}

.sub-page-title {
  margin-top: 16px;
  font-size: 1.3rem;
}

.page-title,
.sub-page-title {
  display: block;
  text-align: left;
  letter-spacing: 0;
  color: #1351b4;
  opacity: 1;
  font-weight: 600;
}

.page-description {
  margin-top: 4%;
  margin-bottom: 2%;
  color: #636363;
}

.page-sub-description {
  margin-top: 1%;
  margin-bottom: 1%;
  color: #636363;
}

.collapsible {
  background-color: #F8F7F7;
  color: #333333;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  font-weight: 500;
  box-shadow: 0 2px 4px #00000033;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #F8F7F7;
  box-shadow: 0 2px 4px #00000033;
}

.my-reliabilities {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.card-levels {
  display: flex;
}

.card-level {
  text-align: center;
  background: #f3f3f3 0 0 no-repeat padding-box;
  box-shadow: 0 2px 4px #00000033;
  padding: 20px;
}

.card-level {
  margin: 15px 20px 20px 0;
}

.card-level:last-child {
  margin-right: 0;
}

.card-level>p:first-child {
  color: #1351B4;
}

.card-level>img {
  width: 100%;
}

.card-level>p:last-child {
  text-align: left;
  padding-left: 15px;
}

ul.advantages {
  text-align: left;
  line-height: 2.3em;
  font-size: 14px;
  list-style: none;
}

ul.advantages>li:before {
  content: "\f00c";
  font-family: FontAwesome;
  color: #5DAF57;
  display: inline-block;
  margin-left: -1.3em;
  width: 1.3em;
}

ul.advantages>li.dont:before {
  content: "\f00d";
  color: #E10D0D;
}

.action-primary, .action-secondary {
  font-size: 14px;
  height: 25px;
  display: inline-block;
}

a#info {
  color: #1351B4;
  cursor: pointer;
  margin: 20px 0;
  font-weight: 600;
}

a#info>i {
  margin-right: 10px;
}

.ouro {
  color: #FFCD06;
}

.prata {
  color: #999999;
}

.bronze {
  color: #F08080;
}

.ouro, .prata, .bronze {
  font-size: 1.3em;
  font-weight: bolder;
  text-transform: uppercase;
}

.card {
  background: #f3f3f3 0 0 no-repeat padding-box;
  box-shadow: 0 2px 4px #00000033;
  padding: 20px;
  margin: 20px auto auto;
  display: inline-block;
  position: relative;
}

.card-icon {
  margin-top: 0;
}

.card-bank {
  background: white 0 0 no-repeat padding-box;
  box-shadow: 0 2px 4px #00000033;
  padding: 20px;
  margin: 20px auto auto;
  text-align: center;
  width: 254px;
}

.card-bank>img {
  width: 30px;
  object-fit: scale-down;
  float: left;
  position: relative;
  top: 0.3em;
}

.card-bank>p {
  font-size: 14px;
  text-align: left;
  margin-left: 40px;
}

.is-active {
  font-weight: bold;
  color: red;
}

@media screen and (max-width: 764px) {
  .card-levels {
    flex-wrap: wrap;
  }

  .card-level {
    margin: 15px 2px 20px 0;
  }
}