#carrossel li {
  font-weight: 600;
  font-size: 17px;
  color: #333333;
  margin: 20px 0 10px 0;
}

.radio-toolbar {
  margin: 10px;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  font-weight: 500;
  padding: 8px 12px;
  border: 1px solid #1351B4;
  border-radius: 10px;
  color: #1351B4;
  margin: 5px;
}

.radio-toolbar label:hover {
  background-color: #88bbe8;
  border-color: #88bbe8;
  color: white;
}

.radio-toolbar input[type="radio"]:checked + label {
  background-color: #1351B4;
  border-color: #1351B4;
  color: white;
}

.radio-toolbar input[type="radio"]:checked + label::before {
  content: '\2713';
  margin-right: 5px;
}

.lojas-app {
  display: flex;
  justify-content: space-around;
}
